.swagger-ui .auth-wrapper {
    min-height: none !important;
}

.swagger-ui .wrapper {
    margin: 0 80px 0 20px !important;
    padding: 0 30px 0 0 !important;
    max-width: none;
}

.swagger-ui .scheme-container {
    margin: 30px !important;
}

.swagger-ui .info .title {
    font-size: 24px !important;
    font-family: 'Muli-semibold' !important;
    color: #141619;
}

.swagger-ui {
    font-family: 'Muli-semibold' !important;
    margin: 0 -30px !important;
    color: #141619 !important
}

.swagger-ui .model-title {
    font-size: 14px !important;
    font-family: 'Muli-semibold' !important;
}

.swagger-ui .btn {
    font-family: 'Muli-semibold' !important;
}

.swagger-ui .opblock-tag {
    font-size: 16px !important;
    font-family: 'Muli-semibold' !important;
}

.swagger-ui section.models h4{
    font-size: 16px !important;
    font-family: 'Muli-semibold' !important;
}

.swagger-ui pre {
    background: #41444e !important;
    color: white !important;
    font-family: courier, monospace !important;
}

.swagger-ui h2 pre {
    background: inherit !important;
    color: inherit  !important;
}

.swagger-ui pre.base-url {
    background: #41444e !important;
    color: white !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    height: 100% !important;
    font-family: courier, monospace !important;
    font-size: 12px !important; 
    font-weight: 600;
    padding: 20px !important;
}

.swagger-ui .info .title small {
    background: #e5e5e5;
}

.swagger-ui textarea {
    background: aliceblue !important;
}
    .scheme-container span.schemes-title, .scheme-container .schemes.wrapper select,
    .swagger-ui .info .title small, .main .link .url, .swagger-ui .info .description .markdown p {
        display: none;
    }

.multi-tenancy + .swagger-ui .scheme-container {
    display: none;
}

.servers select {
    display: block !important;
}
